<div style="width:800px">
    <h2 mat-dialog-title>Choose Option</h2>
    <hr>
    <form [formGroup]="tokenForm">
    <div style="display:flex; flex-direction: row; justify-content: start; align-items:start">
        <label style="font-size: small;margin-left: 10px;margin-right: 10px;margin-top:4px;">Token ARN :</label>
        <input formControlName="tokenArn" readonly style="font-size:small;width:700px" type="text">
    </div>
    </form>
    <br><br>
    <button (click)="checkArnStatus()"
            class="btn btn-primary" color="primary"
            mat-flat-button
            style="margin-left:170px;margin-right: 30px; color:black; width:200px;font-size: medium;"
            type="button">Check Status
    </button>
    <button (click)="applyReplicate()"
            class="btn btn-primary" class="btn btn-primary" color="primary"
            mat-flat-button
            style="color: black;font-size: medium; width:200px;"
            type="button">Replicate Token
    </button>
    <br><br>
    <div *ngIf="status" style="margin-left: 200px;">
        <div class="text-center border border-light p-5">
            <table *ngIf="!replicationStatusMsg">
                <thead>
                <tr>
                    <th>Region</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor='let dataObj of this.replicatedTokensStatus'>
                    <td>{{ dataObj.Region}}</td>
                    <td>{{ dataObj.Status}}</td>
                </tbody>
            </table>
        </div>
        <div *ngIf="replicationStatusMsg">
            <label style="color:red; font-size: medium;text-align: center;padding-left: 5px; padding-right:10px;">Token is not replicated to any regions</label>
        </div>
        <div *ngIf="replicationStatusErrorMsg">
            <label style="color:red; font-size: small;text-align: center;padding-left: 5px; padding-right:10px;">Error : {{this.repStatusError}}</label>
        </div>
    </div>
    <div *ngIf="formfill" style="margin-left: 250px;">
        <form #tokenForm="ngForm" (ngSubmit)="replicateTokenForm(tokenForm.value)" [formGroup]="form"
              class="text-center border border-light p-5">
            <div class="form-group">
                <mat-form-field>
                    <mat-label style="font-size: medium;">Select Regions</mat-label>
                    <mat-select [(ngModel)]="regionInitial" class="form-control" formControlName="region" multiple>
                        <mat-option *ngFor="let region of regions" [value]="region.regionValue">
                            {{region.regionValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="form.get('region')?.hasError('required') && tokenForm.submitted  "
                     style="color: red;margin-left:00px; font-size: small;">
                    <p>Please Choose Regions</p>
                </div>
            </div>
            <button class="btn btn-primary" style="font-size: medium;margin-left: 80px;" type="submit">Update</button>
        </form>
    </div>
    <br><br>
    <div *ngIf="replicate">
        <label style="color:red; font-size: small;text-align: center;padding-left: 10px; padding-right:10px;">Error : {{this.eMsg}}</label>
    </div>
    <div *ngIf="replicationFail">
        <label style="color:red; font-size: small;text-align: center;padding-left: 10px; padding-right:10px;"> Error : {{this.repFailMsg}}</label>
    </div>
    <div *ngIf="errormsg" class="error" style="color: red;margin-left:200px"><p>{{this.errorMsg}}</p></div>
    <br>
    <hr>
    <div align="end" mat-dialog-actions>
    <span>
      <button [mat-dialog-close]="true"
              color="primary"
              mat-flat-button
              style="margin-left:100px;margin-right: 20px;font-size: medium;"
              type="button">Close
      </button>
    </span>
    </div>
</div>
