import {
    Component,
    OnInit
} from '@angular/core';
import {
    AuthService
} from '../auth.service';
import {
    Router
} from '@angular/router';
import {
    environment
} from 'src/environments/environment';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    form: any;
    environmentName: any;
    isDev: boolean = false;
    isStg: boolean = false;
    isProd: boolean = false;
    isQa: boolean = false;
    constructor(public auth: AuthService, private myRoute: Router) {
        this.environmentName = environment.env_name
        if (this.environmentName === 'dev') {
            this.isDev = true;
        }
        if (this.environmentName === 'stg') {
            this.isStg = true;
        }
        if (this.environmentName === 'prod') {
            this.isProd = true;
        }
        if (this.environmentName === 'qa') {
                     this.isQa = true;
                 }
    }

    ngOnInit() {
    }

}