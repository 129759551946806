import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  environment
} from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  accesstoken: any;
  authCode: any;
  private TOKEN_URL = environment.Token_URL;
  private Client_id = environment.client_id;
  private Client_secret = environment.secret_key;
  private redirect_uri:string = environment.redirect_uri;
  private BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient, private router: Router) {
  }

  //++++++ Get Token+++++++//
  getTokens() {
    this.authCode = localStorage.getItem("authCode");
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(
        'Content-Type', 'application/x-www-form-urlencoded'
    );
    let params = new HttpParams()
        .set('code', this.authCode)
        .set('grant_type', 'authorization_code')
        .set('redirect_uri', this.redirect_uri)
        .set('client_id', this.Client_id)
        .set('client_secret', this.Client_secret)
        .set('scope', 'ctp-static-tokens-api/write ctp-static-tokens-api/read');
    const httpOptions = {
      params: params,
      headers: headers
    };
    return this.http.post(this.TOKEN_URL + '/token', null, httpOptions);
  }

  setEnvName(accountName:any){
    if(accountName==='dev-dp')
    {
      this.BASE_URL=environment.BASE_DP_URL
    }
    else{
      this.BASE_URL = environment.BASE_URL;
    }
  }

  //++++++++++++++Get Tokens List++++++++++++++++++++++//
  public getTokensList(accountName:any) {
    this.accesstoken = localStorage.getItem("tokenData");
    this.setEnvName(accountName);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', this.accesstoken);
    const httpOptions = {
      headers: headers
    };
    return this.http.get < any > (this.BASE_URL+ '/list', httpOptions);
  }

  //++++++++Create Token+++++++++++++//
  public createToken(data,accountName) {
    this.accesstoken = localStorage.getItem("tokenData");
    this.setEnvName(accountName);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', this.accesstoken);
    const httpOptions = {
      headers: headers
    };
    return this.http.post < any > (this.BASE_URL + '/create', data, httpOptions);
  }

  //++++++++++Delete Token+++++++++///
  public deleteToken(deletedevtokenarn: any,accountName:any) {
    this.accesstoken = localStorage.getItem("tokenData");
    this.setEnvName(accountName);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization',this.accesstoken);
    headers = headers.append('Content-Type', 'application/json')
    const httpOptions = {
      headers: headers,
      body: {
        "tokenArn": deletedevtokenarn,
        "forceDeleteWithoutRecovery": "False"
      }
    };
    return this.http.delete < any > (this.BASE_URL + '/delete', httpOptions);
  }

  //+++++++++++++++++Add Policy++++++++++++++++++//
  public tokenAllowPolicy(data: any,accountName) {
    this.accesstoken = localStorage.getItem("tokenData");
    this.setEnvName(accountName);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', this.accesstoken);
    const httpOptions = {
      headers: headers,
    };
    return this.http.post < any > (this.BASE_URL + '/allow', data, httpOptions);
  }

  //+++++++++++++++++++++++Replicate Status++++++++++++++++++++++//
  public replicateTokenStatus(replicateArn: any,accountName) {
    this.accesstoken = localStorage.getItem("tokenData");
    this.setEnvName(accountName);
    let encodeArn = encodeURIComponent(replicateArn);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', this.accesstoken);
    let params = new HttpParams().set('tokenArn', encodeArn);
    const httpOptions = {
      params: params,
      headers: headers
    };

    return this.http.get(this.BASE_URL + '/replication', httpOptions);
  }

  //+++++++++++++++++Apply Replicate ++++++++++++++++++//
  public replicateToken(data: any,accountName) {
    this.accesstoken = localStorage.getItem("tokenData");
    this.setEnvName(accountName);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', this.accesstoken);
    const httpOptions = {
      headers: headers
    };
    return this.http.post(this.BASE_URL + '/replication', data, httpOptions);
  }

  //++++++++++++++++++++ Split arns +++++++++++++++++
  public getTokenListDetails(splitTokenList:any,accountName:any) {
    this.accesstoken = localStorage.getItem("tokenData");
    this.setEnvName(accountName);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization','Bearer '+this.accesstoken);
    const httpOptions = {
      headers: headers,
    };
    let data = {
      "arns":splitTokenList
    }
    return this.http.post(this.BASE_URL + '/list/details',data,httpOptions);
  }

}
