<div style="width:800px">
    <h2 mat-dialog-title>Delete Token</h2>
    <hr>
    <div>
        <form (ngSubmit)="deleteArn()" [formGroup]="tokenForm">
            <div style="display:flex; flex-direction: row; justify-content: start; align-items: start">
                <label style="font-size: small;margin-left: 10px;margin-right: 10px;">Token ARN :</label>
                <input formControlName="tokenArn" readonly style="font-size: small;width:700px" type="text">
            </div>
            <p style="color: red;margin-left:200px;">Please confirm to Delete the Token ARN</p>
            <br>
            <button class="btn btn-primary" style="margin-left:300px; font-size: medium;" type="submit">Confirm</button>
        </form>
        <div *ngIf="errormsg" class="error" style="color: red;margin-left:200px;font-size: small">
            <p>Something went wrong ... Please try again</p>
        </div>
    </div>
    <br>
    <hr>
    <div align="end" mat-dialog-actions>
    <span>
      <button [mat-dialog-close]="true" color="primary" mat-flat-button style="margin-left:100px;margin-right: 20px;"
              type="button">Close
      </button>
    </span>
    </div>
</div>