import {
  Component
} from '@angular/core';

import {
  Router
} from '@angular/router';
import {
  AuthService
} from '../auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  tokendata: any;
  constructor(public auth: AuthService, private router: Router) {}

}