import {
    Component, ViewChild
} from '@angular/core';
import {
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';
import {
    AddPolicyModalComponent
} from '../add-policy-modal/add-policy-modal.component';
import {
    DeleteModalComponent
} from '../delete-modal/delete-modal.component';
import {
    ReplicateModalComponent
} from '../replicate-modal/replicate-modal.component';
import {
    environment
} from 'src/environments/environment';
import {LoaderService} from '../services/loader.service';
import {ApiService} from "../services/api.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
export interface Element {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

export interface ARNData {
    ARN:any;
    value:any;
}
@Component({
    selector: 'app-list-static-token',
    templateUrl: './list-static-token.component.html',
    styleUrls: ['./list-static-token.component.scss']
})
export class ListStaticTokenComponent {
    name = 'Angular 5';
    displayedColumns = ['ARN','Sender', 'Audience','Expiration' ,'Replicate', 'Add Policy', 'Delete'];
    accountId: String = '';
    devDpTokens: String[] = [];
    devTokens: String[] = [];
    devDpAccount: any;
    devDpcount: any;
    devAccount: any;
    devcount: any;
    environmentName: any;
    isDev: boolean = false;
    isStg: boolean = false;
    isProd: boolean = false;
    isQa:  boolean = false;
    matDialogRef: MatDialogRef<ReplicateModalComponent> | undefined;
    matDialogPolicyRef: MatDialogRef<AddPolicyModalComponent> | undefined;
    matDialogDeleteRef: MatDialogRef<DeleteModalComponent> | undefined;
    accountCount: any;
    tokensList: any;
    loading: any;
    accountName: any;
    tokenListFail: boolean = false;
    arnDataSource: MatTableDataSource<ARNData>=new MatTableDataSource;
    arnDataDpSource: MatTableDataSource<ARNData>=new MatTableDataSource;
    paginator:any;
    sort:any;

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
    }
    @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
        this.arnDataSource.sort = sort;
        this.arnDataDpSource.sort=sort

    }
    @ViewChild('sorter1') sorter1: MatSort = new MatSort;
    @ViewChild('sorter2') sorter2: MatSort = new MatSort;
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
    }

    constructor(private loaderService: LoaderService, private matDialog: MatDialog, private apiService: ApiService) {
        {
            this.loaderService.isLoading.subscribe((v) => {
                this.loading = v;
            });
        }
        this.environmentName = environment.env_name
        if (this.environmentName === 'dev') {
            this.isDev = true;
        }
        if (this.environmentName === 'stg') {
            this.isStg = true;
        }
        if (this.environmentName === 'prod') {
            this.isProd = true;
        }
        if (this.environmentName === 'qa') {
            this.isQa = true;
        }
    }

    ngOnInit(): void {
        if (this.environmentName === 'dev') {
            this.fetchTokensList();
            this.fetchDpTokensList();
        }
        if (this.environmentName === 'stg' || this.environmentName === 'prod' || this.environmentName === 'qa') {
            this.fetchTokenList();
        }
        this.arnDataSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
            return item[property];
        };
        this.arnDataSource.sort=this.sorter1;
        this.arnDataDpSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
            return item[property];
        };
        this.arnDataDpSource.sort=this.sorter2;
    }
    //new
    ngAfterViewInit() {
        this.arnDataSource.sort=this.sorter1;
        this.arnDataDpSource.sort=this.sorter2;
    }
    setDataSourceAttributes() {
        this.arnDataSource.sort=this.sorter1;
        this.arnDataDpSource.sort=this.sorter2;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.arnDataSource.filter = filterValue.trim().toLowerCase();
    }
    applyDpFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.arnDataDpSource.filter = filterValue.trim().toLowerCase();
    }
    //split list function
    getSplitTokenListDetails(devTokens:any,accountName:any){
        const arnslist: ARNData[] = [];
        let splitDevToken:any
        this.apiService.getTokenListDetails(devTokens,accountName).subscribe(response =>{
                splitDevToken=response;
                Object.keys(splitDevToken.details).map(function (key,value) {
                    const splitTokenObject = <ARNData >{};
                    splitTokenObject.ARN=key;
                    splitTokenObject.value=splitDevToken.details[key];
                    const myUnixTimestamp = splitTokenObject.value.exp // start with a Unix timestamp
                    const myDate = new Date(myUnixTimestamp * 1000); //convert into readable timestamp
                    splitTokenObject.value.exp = myDate.toLocaleString();
                    arnslist.push(splitTokenObject);
                });
                if(accountName==='dev-dp'){
                    this.arnDataDpSource = new MatTableDataSource<ARNData>(arnslist)
                    this.arnDataDpSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
                        return item[property];
                    };
                    this.arnDataDpSource.sort=this.sorter2;
                }
                else{
                    this.arnDataSource = new MatTableDataSource<ARNData>(arnslist)
                    this.arnDataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
                        return item[property];
                    };
                    this.arnDataSource.sort=this.sorter1;
                }
            },
            (error: any) => {
                console.log('Splitting of tokens list loading failed', error);
            });
    }

    fetchTokensList() {
        this.accountName = 'dev';
        this.apiService.getTokensList(this.accountName).subscribe(response => {
                let tokenList = response;

                if(tokenList.arns){
                    this.devTokens = tokenList.arns;
                    this.devAccount = tokenList.accoundId;
                    this.devcount = tokenList.count;
                    this.tokenListFail= false; // fetching of token list fail
                }
                this.getSplitTokenListDetails(this.devTokens,'dev');
            },
            (error: any) => {
                this.tokenListFail= true;
                console.log('Tokens list loading failed', error);
            }
        );
    }
    fetchDpTokensList() {
        this.accountName = 'dev-dp';
        this.apiService.getTokensList(this.accountName).subscribe(response => {
                let tokenList = response;
                if(tokenList.arns){
                    this.devDpTokens = tokenList.arns;
                    this.devDpAccount = tokenList.accoundId;
                    this.devDpcount = tokenList.count;
                    this.tokenListFail= false;
                }
                this.getSplitTokenListDetails(this.devDpTokens,'dev-dp');
            },
            (error: any) => {
                this.tokenListFail= true;
                console.log('Tokens list loading failed - 482599918507 account', error)
            }
        );
    }
    devDpDeleteToken(deleteDevDpToken: any) {
        this.matDialogDeleteRef = this.matDialog.open(DeleteModalComponent, {
            data: {
                token: deleteDevDpToken,
                account: "Dev-dp"
            },
            disableClose: true
        });
        this.matDialogDeleteRef.afterClosed().subscribe((res: any) => {
            if ((res == true)) {
                this.name = "";
            }
        });
    }

    devDpAddPolicyToken(policyDevDpToken: any) {
        this.matDialogPolicyRef = this.matDialog.open(AddPolicyModalComponent, {
            data: {
                token: policyDevDpToken,
                account: "Dev-dp"
            },
            disableClose: true
        });
        this.matDialogPolicyRef.afterClosed().subscribe((res: any) => {
            if ((res == true)) {
                this.name = "";
            }
        });
    }

    devDpReplicateTokenStatus(replicateDevDpToken: any) {
        this.matDialogRef = this.matDialog.open(ReplicateModalComponent, {
            data: {
                token: replicateDevDpToken,
                account: "Dev-dp"
            },
            disableClose: true
        });
        this.matDialogRef.afterClosed().subscribe((res: any) => {
            if ((res == true)) {
                this.name = "";
            }
        });
    }

    /////+++++++++++++Dev Token Operations++++++++++++++
    devDeleteToken(deleteDevToken: any) {
        this.matDialogDeleteRef = this.matDialog.open(DeleteModalComponent, {
            data: {
                token: deleteDevToken,
                account: "Dev"
            },
            disableClose: true
        });
        this.matDialogDeleteRef.afterClosed().subscribe((res: any) => {
            if ((res == true)) {
                this.name = "";
            }
        });
    }

    devAddPolicyToken(policyDevToken: any) {
        this.matDialogPolicyRef = this.matDialog.open(AddPolicyModalComponent, {
            data: {
                token: policyDevToken,
                account: "Dev"
            },
            disableClose: true
        });
        this.matDialogPolicyRef.afterClosed().subscribe((res: any) => {
            if ((res == true)) {
                this.name = "";
            }
        });
    }


    devReplicateTokenStatus(replicateDevToken: any) {
        this.matDialogRef = this.matDialog.open(ReplicateModalComponent, {
            data: {
                token: replicateDevToken,
                account: "Dev"
            },
            disableClose: true
        });
        this.matDialogRef.afterClosed().subscribe((res: any) => {
            if ((res == true)) {
                this.name = "";
            }
        });
    }

    selectedTabChange(event: any) {
    }

    //STG/PROD/QA table
    fetchTokenList() {
        this.apiService.getTokensList(this.accountName).subscribe((response: any) => {
                let tokenList = response;
                if(tokenList.arns){
                    this.tokensList = tokenList.arns;
                    this.accountId = tokenList.accoundId;
                    this.accountCount = tokenList.count;
                    this.tokenListFail= false;
                }
                this.getSplitTokenListDetails(this.tokensList,'stg/prod/qa');
            },
            (error: any) => {
                this.tokenListFail= true;
                console.log('Tokens list loading failed', error)
            }
        );
    }

    applySPFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.arnDataDpSource.filter = filterValue.trim().toLowerCase();
    }

    ReplicateTokenStatus(token) {
        this.matDialogRef = this.matDialog.open(ReplicateModalComponent, {
            data: {
                token: token,
                account: "stg/prod/qa"
            },
            disableClose: true
        });
    }

    AddPolicyToken(token) {
        this.matDialogPolicyRef = this.matDialog.open(AddPolicyModalComponent, {
            data: {
                token: token,
                account: "stg/prod/qa"
            },
            disableClose: true
        });
    }

    DeleteToken(token) {
        this.matDialogDeleteRef = this.matDialog.open(DeleteModalComponent, {
            data: {
                token: token,
                account: "stg/prod/qa"
            },
            disableClose: true
        });
    }
}


