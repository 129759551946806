<app-nav></app-nav>
<router-outlet></router-outlet>

<mat-sidenav-container *ngIf="auth.isLoggedIn()" class="example-container">
  <mat-sidenav mode="side" opened class="opened-sidebar">
    <nav>
      <ul>
        <a *ngIf="auth.isLoggedIn()" routerLink="/createToken">Create Token</a>
        <br><br><br><br>
        <a *ngIf="auth.isLoggedIn()" routerLink="/listOfToken">List Of Static Tokens</a>
      </ul>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content *ngIf="auth.isLoggedIn()" class="opened-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>