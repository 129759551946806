import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {environment} from 'src/environments/environment';
import {ApiService} from "./services/api.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'automatic-ctp-static-token-tool';
    accessToken: any;
    environmentName: string;

    constructor(private router: Router, public auth: AuthService, private apiService: ApiService) {
        this.environmentName = environment.env_name;
    }

    ngOnInit() {
        this.getToken();
    }

    //Split url to fetch authcode
    getToken() {
        var url = window.location.search;
        const authCode = url.replace("?code=", '');
        localStorage.setItem("authCode", authCode);
        this.getTokens();
    }

    getTokens() {
        this.apiService.getTokens().subscribe((response) => {
            const tokenData = response;
            const tokenArray = Object.values(tokenData);
            this.accessToken = tokenArray[1];
            localStorage.setItem("tokenData", this.accessToken);
        });
    }
}