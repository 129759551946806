<div style="width:800px">
    <h2 mat-dialog-title>Add Policy</h2>
    <hr>
    <div>
        <form #tokenForm="ngForm" (ngSubmit)="addPolicyArn()" [formGroup]="form">
            <div style="display:flex; flex-direction: row; justify-content: start; align-items: start">
                <label style="font-size: small;margin-left: 10px;padding-right: 25px;">Token ARN :</label>
                <input formControlName="tokenArn" readonly style="font-size: small;width:680px" type="text">
            </div>
            <br>
            <div class="form-group">
                <label for="audience" style="font-size: small;margin-left: 10px;padding-right: 15px;">Principal ARN :</label>
                <input class="form-control" formControlName="principalArn" id="principalArn" name="principalArn" placeholder="Principal Arn"
                       required size="100" type="text">
                <div *ngIf="form.get('principalArn')?.hasError('required') && tokenForm.submitted  "
                     style="color: red;margin-left:110px; font-size: small;">
                    <p>Please enter principal Arn</p>
                </div>
            </div>
            <br>
            <button class="btn btn-primary" style="font-size: medium;margin-left:300px;;" type="submit">Add Policy
            </button>
            <br><br>
        </form>
    </div>
    <br>
    <div *ngIf="errormsg" class="error" style="color: red;margin-left:200px"><p>Something went wrong...Please try
        again</p></div>
    <div *ngIf="policyUpdate">
        <label for="kmsArn" style="color:red; font-size: small;margin-left:10px; padding-right: 10px;">KMS Key Policy Updated</label>
        <br>
        <label for="kmsArn" style="font-size: small;margin-left:10px; padding-right: 10px;">Kms Key ARN :</label>
        <label #kmsKey style="font-size: small;width: 700px;margin-right: 10px;">  {{this.kmsArn}}</label>
        <mat-icon matTooltip="Copy" style="font-size: x-small;">
            <svg (click)="copyToClipboardWithParameter(kmsKey)" class="bi bi-copy" fill="currentColor" height="16" viewBox="0 0 16 16"
                 width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                      fill-rule="evenodd"/>
            </svg>
        </mat-icon>
    </div>
    <div *ngIf="policyError">
        <label style="color:red; font-size: small;text-align: center;padding-left: 10px; padding-right:10px;">Error : {{this.eMsg}}</label>
    </div>
    <br>
    <hr>
    <div align="end" mat-dialog-actions>
    <span>
      <button [mat-dialog-close]="true"
              color="primary"
              mat-flat-button
              style="margin-left:100px;margin-right: 20px;font-size: medium;"
              type="button">Close
      </button>
    </span>
    </div>
</div>






