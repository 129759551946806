import {
    Component,
    OnInit
} from '@angular/core';
import {
    FormBuilder,
    Validators
} from '@angular/forms';
import {
    Router
} from '@angular/router';
import {
    AuthService
} from '../auth.service';
import {
    environment
} from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    form;
    environmentName: any;
    isDev: boolean = false;
    isStg: boolean = false;
    isProd: boolean = false;
    isQa: boolean = false;
    URL: any;
    private DEV_LOGIN_URL: any;
    private STG_LOGIN_URL: any;
    private PROD_LOGIN_URL: any;
    private QA_LOGIN_URL: any;
    private LOGIN_URL = environment.LOGIN_URL + "?" + "client_id=" + environment.client_id + "&response_type=" + environment.response_type + "&scope=" + environment.scope + "&redirect_uri=" + environment.redirect_uri;

    constructor(private fb: FormBuilder,
                private myRoute: Router,
                private auth: AuthService) {
        this.form = fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        this.environmentName = environment.env_name
        if (this.environmentName === 'dev') {
            this.isDev = true;
            this.DEV_LOGIN_URL = this.LOGIN_URL;
        }
        if (this.environmentName === 'stg') {
            this.isStg = true;
            this.STG_LOGIN_URL = this.LOGIN_URL;
        }
        if (this.environmentName === 'prod') {
            this.isProd = true;
            this.PROD_LOGIN_URL = this.LOGIN_URL;
        }
        if (this.environmentName === 'qa') {
            this.isQa = true;
            this.QA_LOGIN_URL = this.LOGIN_URL;
        }
    }

    ngOnInit() {
    }
    onLogin() {
        if(this.environmentName === 'dev'){
            this.URL = this.DEV_LOGIN_URL;
        }
        if(this.environmentName === 'stg'){
            this.URL = this.STG_LOGIN_URL;
        }
        if(this.environmentName === 'prod'){
            this.URL = this.PROD_LOGIN_URL;
        }
        if(this.environmentName === 'qa'){
            this.URL = this.QA_LOGIN_URL;
        }
        const auth = window.location.assign(this.URL);
        var url = window.location.search;
        this.auth.sendToken(this.form.value.email);
        this.myRoute.navigate(["home"]);
    }
}