<div class="card">
    <h5 class="card-header" style="text-align: center;">Create Static Token</h5>
    <div class="card-body">

        <form (keydown.enter)="$event.preventDefault()" [formGroup]="form" #tokenForm="ngForm" (ngSubmit)="createToken(tokenForm.value)"
            class="text-center border border-light p-5">
           <div class="mb-4">
                <div class="form-group">
                    <label style="margin-left:150px;" for="sender">Sender :</label>
                    <input style="margin-left:68px" size="100" name="sender" id="sender" type="text"
                        formControlName="sender" class="form-control" placeholder="sender" required>
                    <div *ngIf="form.get('sender')?.hasError('required') && tokenForm.submitted">
                        <p style="color: red;margin-left:280px; font-size: small;">Please enter sender</p>
                    </div>
        </div>
        </div>
            <br><br>
            <div class="mb-4">
                <div class="form-group">
                    <label style="margin-left:150px;" for="audience">Audience :</label>
                    <input style="margin-left:50px" size="100" name="audience" id="audience" type="text"
                        formControlName="audience" class="form-control" placeholder="audience" required>
                    <div *ngIf="form.get('audience')?.hasError('required') && tokenForm.submitted  "
                        style="color: red;margin-left:280px; font-size: small;">

                        <p>Please enter audience</p>

                    </div>
                </div>

            </div>
            <br><br>

            <div class="mb-4" *ngIf="isDev">
                <div class="form-group">
                    <label style="margin-left:150px;" for="audience">Account:</label>
                    <mat-radio-group aria-label="Select an option" class="form-control" style="margin-left:50px">
                        <mat-radio-button  value="1" (change)="radioButtonChange($event)">
                        685623477725
                        </mat-radio-button>
                        <mat-radio-button value="2" (change)="radioButtonChange($event)">
                        482599918507
                        </mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="form.get('audience')?.hasError('required') && tokenForm.submitted  "
                        style="color: red;margin-left:280px; font-size: small;">

                        <p>Please enter Audience</p>

                    </div>
                </div>

            </div>
          
  <button  style="margin-left:750px;margin-right: 20px;background-color:dodgerblue ;color:white"
  (click)="reloadPage()">Clear Token</button>
 
  <button class="btn btn-primary" style="background-color:dodgerblue ;color:white;" type="submit">Create Token</button>
  <br><br>
   <!-- progress bar-->
   <div class="loader-container">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
<br><br>
  </form>

        
<div *ngIf="tokendetail && tokendetail.length !=0">

    <div *ngIf="tokenFlag">
      <label  style="margin-left:150px;" for="tokenArn">Token ARN :</label>
      <!-- <label #tokenArnKey style="margin-left:30px; margin-right: 10px;" >  {{this.tokenArn}}</label>   <mat-icon matTooltip="My tooltip">delete</mat-icon>-->
      <label  #tokenArnKey style="width: 50px;margin-right: 10px;" >  {{this.tokenArn}}</label>
      <mat-icon  style="font-size: x-small;" matTooltip="Copy"><svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" (click)="copyToClipboardWithParameter(tokenArnKey)">
        <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
      </svg></mat-icon>
      <br><br>
    </div>
  
  
   <div *ngIf="tokenFlag">
    <label style="margin-left:150px;" for="kmsKeyArn">Kms Key ARN : </label>
    <!-- <label  #kmsKey style="margin-left:16px; margin-right: 10px;">  {{this.kmsKeyArn}}</label> -->
    <label   #kmsKey style="width: 50px;margin-right: 10px;">  {{this.kmsKeyArn}}</label>
    
    <mat-icon style="font-size: x-small;" matTooltip="Copy">
      <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" (click)="copyToClipboardWithParameter(kmsKey)">
        <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
      </svg>
    </mat-icon>
    <br><br>
   </div>
  
  
  
  <div>
   <label *ngIf="tokenFlag" style="margin-left:150px;margin-right: 20px;color:red; font-size: small;">Note:- You can replicate this token to other regions as per the requirement by clicking on Replicate button in 'List of Static Tokens' screen</label>
  </div>
  <div>
    <label *ngIf="tokenFailure" style="margin-left:150px;margin-right: 20px;color:red; font-size: small;">Error : {{this.eMsg}}</label>
   </div>
   </div>
 </div>
 </div>
  
  
  