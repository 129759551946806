export const environment =
    {
        production: false,
        env_name: 'qa',
        API_URL: 'https://ctp-static-tool-ui.mimecastidentity-qa.com/',
        BASE_URL: 'https://zktw0l33i4.execute-api.eu-west-2.amazonaws.com/qa',
        BASE_DP_URL: '',
        Token_URL: 'https://ctp-static-tokens-tool-qa.auth.eu-west-2.amazoncognito.com/oauth2',
        response_type: 'code',
        client_id:'1cp6unv3m7fap35kpe09nb60go',
        secret_key:'1epgnif4khdft4ik91n438eu1blmpc3qasd76hcappictpn775q7',
        scope: 'ctp-static-tokens-api/read ctp-static-tokens-api/write email openid',
        redirect_uri: 'https://ctp-static-tool-ui.mimecastidentity-qa.com',
        LOGIN_URL: 'https://ctp-static-tokens-tool-qa.auth.eu-west-2.amazoncognito.com/login',
        Region: [{
            value: '1',
            regionValue: 'ap-southeast-2'
        },
            {
                value: '2',
                regionValue: 'eu-central-1'
            },
            {
                value: '3',
                regionValue: 'us-east-1'
            },
            {
                value: '4',
                regionValue: 'ca-central-1'
            },
            {
                value: '5',
                regionValue: 'af-south-1'
            },
            {
                value: '6',
                regionValue: 'us-west-2'
            },
        ]
    };