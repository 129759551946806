import {
    Component,
    Inject
} from '@angular/core';
import {
    FormBuilder
} from '@angular/forms';
import {
    MAT_DIALOG_DATA
} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
    output: any;
    delete: boolean = false
    addPolicy: boolean = false;
    replicate: boolean = false;
    addpolicyMessage: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder) {
        this.output = data
        if (this.output.operation === "delete") {
            this.delete = true;
            this.addPolicy = false;
            this.replicate = false
        }
        if (this.output.operation === "addPolicy") {
            this.addpolicyMessage = this.output.kmsKeyArn;
            this.delete = false;
            this.addPolicy = true;
            this.replicate = false
        }
        if (this.output.operation === "Replicate") {
            this.delete = false;
            this.addPolicy = false;
            this.replicate = true
        }
    }


}