<div *ngIf="delete" style="width:500px">
    <br>
    <div>
        <p style="padding-left: 10px;">Token deleted successfully.....</p>
    </div>
</div>

<div *ngIf="replicate" style="width:500px">
    <br>
    <div style="margin-left: 30px;">
        <p>Token Replicated Successfully....</p>
    </div>
</div>
<br>
<hr>
<div align="end" mat-dialog-actions>
  <span>
    <button [mat-dialog-close]="true" color="primary" mat-flat-button style="margin-left:100px;margin-right: 20px;"
            type="button">Close
    </button>
  </span>
</div>